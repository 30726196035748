import revive_payload_client_PygwXUFgNf from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_ezmlrpqurmzs4yfgqsu47mkiai/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VWsowuZ9v7 from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_ezmlrpqurmzs4yfgqsu47mkiai/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_D920wz2dir from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_ezmlrpqurmzs4yfgqsu47mkiai/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_yfY4C64Hdv from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.6_terser@5.3_75x5lbl6ekhp5ue2zd5ojr72qm/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_joyaKjdnqo from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_ezmlrpqurmzs4yfgqsu47mkiai/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0p124FwhTl from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_ezmlrpqurmzs4yfgqsu47mkiai/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_VlO2xFfq61 from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_ezmlrpqurmzs4yfgqsu47mkiai/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2PcDe3O2JQ from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_ezmlrpqurmzs4yfgqsu47mkiai/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/.nuxt/components.plugin.mjs";
import prefetch_client_WWpnQghgqB from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.6_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_ezmlrpqurmzs4yfgqsu47mkiai/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_g4Z5dgbZVP from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.24.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import switch_locale_path_ssr_jVLfBSm2Og from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ipqiXPJvN0 from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.12_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import formkitPlugin_pZqjah0RUG from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/.nuxt/formkitPlugin.mjs";
import plugin_hzI5ZeJuDz from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_graphql-ws@5.16.0_graphql@16.9.0__react-dom@18.3.1_rollup_xnanpa4jlhiohslernmsge4bce/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_rjzuUVPZYo from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_rollup@4.24.0_vite@5.4.9_@types+node@22.7.6_terser@5.36.0__vue@3.5.12_typescript@5.6.3_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import errors_P0E5O4Fqzd from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_magicast@0.3.5_nuxt@3.12.4_@parcel+watc_lknukm2v3kq2s5z3lzbjz3wwym/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_6srwWbX0dA from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.1.0_@bugsnag+core@7.25.0_magicast@0.3.5_nuxt@3.12.4_@parcel+watc_lknukm2v3kq2s5z3lzbjz3wwym/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_inVHZavkPo from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__magi_sybeq6lbuknpozu3tvbnj2czh4/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_F0SeJypOxQ from "/data/www/mijn.khn.nl/mijn-khn/releases/52/bezoek/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.4.0_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__magi_sybeq6lbuknpozu3tvbnj2czh4/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
export default [
  revive_payload_client_PygwXUFgNf,
  unhead_VWsowuZ9v7,
  router_D920wz2dir,
  _0_siteConfig_yfY4C64Hdv,
  payload_client_joyaKjdnqo,
  navigation_repaint_client_0p124FwhTl,
  check_outdated_build_client_VlO2xFfq61,
  chunk_reload_client_2PcDe3O2JQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WWpnQghgqB,
  plugin_g4Z5dgbZVP,
  switch_locale_path_ssr_jVLfBSm2Og,
  i18n_ipqiXPJvN0,
  formkitPlugin_pZqjah0RUG,
  plugin_hzI5ZeJuDz,
  plugin_rjzuUVPZYo,
  errors_P0E5O4Fqzd,
  i18n_6srwWbX0dA,
  apollo_inVHZavkPo,
  sofie_required_version_F0SeJypOxQ
]