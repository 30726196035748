<template>
  <NuxtLayout>
    {{ error.statusCode }} - {{ error.message }}

    <button @click="handleError">
      Terug naar home
    </button>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type {NuxtError} from '#app';

const props = defineProps<{
  error: NuxtError,
}>();

useSeoMeta({
  title: props.error.message,
});

function handleError() {
  clearError({redirect: '/'});
}
</script>
